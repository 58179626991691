import { Component, Prop, Vue } from 'vue-property-decorator'
import { Group, User } from '@/Models'
import http from '@/services'
import Search from '@/components/Search/index.vue'
import UserForm from '@/components/UsersForm/UsersForm.vue'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import modalComponent from '@/components/ModalComponent/ModalComponent.vue'

@Component({
  components: {
    Search,
    UserForm,
    ModalDelete,
    modalComponent,
  },
})
export default class preView extends Vue {
  @Prop({ required: false }) template!: any

  mounted() {
    console.log(this.template, 'TEMPLATE')
  }
}
