import { Component, Vue } from 'vue-property-decorator'
import http from '@/services'
import Search from '@/components/Search/index.vue'
import ShowPreview from '@/components/CMS/Preview/preview.vue'
import TemplatesForm from '@/components/CMS/TemplatesForm/index.vue'
import menuModule from '@/store/menu'

@Component({
  components: {
    Search,
    TemplatesForm,
    ShowPreview,
  },
})
export default class PagesMenu extends Vue {
  data: any = []
  showPreview = false
  templateEdit: any = {}

  action = 'list'
  async mounted() {
    await this.getTemplates()
  }

  filterItems(items: any) {
    this.data = items
  }

  async getTemplates() {
    try {
      const res = await http.get('hoshin/page/')
      this.data = res.data.map((x: any) => ({
        ...x,
        activeTab: 0,
      }))
      console.log('DATA', this.data)
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }

  editPage(page: any) {
    console.log(page)
  }

  deletePage(page: any) {
    console.log(page)
  }

  showTemplate(template: any) {
    this.templateEdit = template
    this.action = 'showPreview'
  }
}
